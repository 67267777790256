import React from "react";
import styled from "styled-components";
import { theme } from "../../theme/theme";

const Bar = styled.span`
  position: absolute;
  right: 0;
  display: block;
  height: 3px;
  width: 100%;
  border-radius: 1.5px;
  background-color: ${theme.palette.common.black};
  transform-origin: center center;
  transform: translateZ(0) translateY(0) rotate(0);
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out,
    background-color 0.2s ease-in-out;

  &:first-child {
    top: 0;
  }

  &:nth-child(2) {
    top: 50%;
    margin-top: -1.5px;
    opacity: 1;
  }

  &:last-child {
    bottom: 0;
  }
`;

export interface TogglerWrapperPropsModel {
  isToggled: boolean;
}

const Wrapper = styled.button<TogglerWrapperPropsModel>`
  position: relative;
  display: block;
  width: 1.625em;
  height: 17px;
  padding: 0;
  border: 0;
  font: inherit;
  background: transparent;
  cursor: pointer;
  perspective: 1000px;

  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;

  &:-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  ${(props) => {
    if (props.isToggled) {
      return `
        ${Bar} {
          &:first-child {
            transform: translateZ(0) translateY(7px) rotate(45deg);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:last-child {
            opacity: 1;
            transform: translateZ(0) translateY(-7px) rotate(-45deg);
          }
        }
      `;
    }
  }}
`;

interface StateModel {
  toggled: boolean;
}

interface PropsModel {
  className: string;
  onToggle: (toggled: boolean) => void;
}

export class Toggler extends React.Component<PropsModel, StateModel> {
  constructor(props: PropsModel) {
    super(props);

    this.state = {
      toggled: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle(): void {
    this.setState(
      (previousState) => ({
        toggled: !previousState.toggled,
      }),
      () => this.props.onToggle(this.state.toggled)
    );
  }

  render() {
    return (
      <Wrapper
        className={this.props.className}
        onClick={this.toggle}
        aria-expanded={this.state.toggled}
        isToggled={this.state.toggled}
        aria-label={this.state.toggled ? "afficher le menu" : "masquer le menu"}
      >
        <Bar />
        <Bar />
        <Bar />
      </Wrapper>
    );
  }
}
