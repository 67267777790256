import React from "react";
import { createContext } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import { scrollToTop } from "../utils/scroll-to-top";

import { CenteredRow } from "./common/layout";
import { ConditionalWrapper } from "./common/conditional-wrapper";
import { Toggler } from "./common/toggler";
import { theme } from "../theme/theme";
import { Menu, MenuList, MenuListItem } from "./menu";
import LogoSrc from "../images/joko.svg";

export const HeaderMenuOpenedContext = createContext(false);

export const headerGreaterThanMDMediaQuery =
  "@media screen and (min-width: 59.375em)"; // 950px
export const headerLowerThanMDMediaQuery =
  "@media screen and (max-width: 59.3125em)"; // 949px

const StyledCenteredRow = styled(CenteredRow)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Wrapper = styled.header<{ isSticked: boolean }>`
  position: sticky;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.625em 0;
  margin-top: 2.5em;

  &::before {
    content: " ";
    display: block;
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding-top: 2.5em;
    border-bottom: 1px solid transparent;
    transition: background-color 0.2s ease-in, border-color 0.2s ease-in-out;

    ${(props) => {
      if (props.isSticked) {
        return `
          background-color: ${theme.palette.common.white};
          border-color: ${theme.palette.common.grey};
        `;
      }
    }}
  }
`;

const LogoLink = styled(Link)`
  position: relative;
  z-index: 1;
  display: block;
  line-height: 0;

  ${(props) => {
    if (props.state.isMenuOpened || props.state.isCloseTransitionRunning) {
      return `
        ${headerLowerThanMDMediaQuery} {
          filter: invert(1);
          outline-color: ${theme.palette.secondary.inverted};
        }
      `;
    }
  }}
`;

const Logo = styled.img`
  height: 1.875em;

  ${headerGreaterThanMDMediaQuery} {
    height: 3.5em;
  }
`;

const StyledMenu = styled(Menu)<{
  isOpened: boolean;
  isCloseTransitionRunning: boolean;
  isOnIntro: boolean;
  isMenuItemTransitionRunning: boolean;
}>`
  @keyframes OPENMENU {
    0% {
      clip-path: ellipse(80em 100% at 50% -100%);
    }

    100% {
      clip-path: ellipse(80em 100% at 50% 20%);
    }
  }

  @keyframes CLOSEMENU {
    0% {
      clip-path: ellipse(80em 100% at 50% 20%);
    }

    100% {
      clip-path: ellipse(80em 100% at 50% -100%);
    }
  }

  ${headerLowerThanMDMediaQuery} {
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    background-color: ${theme.palette.common.white};
    filter: invert(1);
    overflow: auto;

    ${MenuList} {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0;
      padding: ${3.125 + 5.625}em 1.25em 0;
    }

    ${MenuListItem} {
      width: 100%;
      align-items: center;
      display: flex;
      flex-direction: column;
      margin: 0;
      padding-bottom: 3.125em;

      &:first-child,
      &:last-child {
        margin-top: auto;
      }
    }
  }

  ${headerGreaterThanMDMediaQuery} {
    ${MenuListItem}:not(:last-child) {
      transition: opacity 0.2s ease-in-out;
      ${(props) => {
        return `
          opacity: ${props.isOnIntro ? "0" : "1"};
          visibility: ${
            props.isIntroVisible && !props.isMenuItemTransitionRunning
              ? "hidden"
              : "visible"
          };
        `;
      }}
    }
  }

  ${(props) => {
    if (props.isOpened) {
      return `
        ${headerLowerThanMDMediaQuery} {
          display: flex;
          animation-duration: 1s;
          animation-name: OPENMENU;
          animation-direction: normal;
          animation-timing-function: ease-out;
        }
      `;
    }

    if (props.isCloseTransitionRunning) {
      return `
        ${headerLowerThanMDMediaQuery} {
          display: flex;
          animation-duration: .5s;
          animation-name: CLOSEMENU;
          animation-timing-function: ease-in;
        }
      `;
    }
  }}
`;

const StyledToggler = styled(Toggler)<{
  isMenuOpened: boolean;
  isCloseTransitionRunning: boolean;
}>`
  ${headerLowerThanMDMediaQuery} {
    z-index: 1;
    display: initial;

    ${(props) => {
      if (props.isMenuOpened || props.isCloseTransitionRunning) {
        return `
          filter: invert(1);
          outline-color: ${theme.palette.secondary.inverted};
        `;
      }
    }}
  }

  ${headerGreaterThanMDMediaQuery} {
    display: none;
  }
`;

interface PropsModel {
  isHome?: boolean;
}

interface StateModel {
  isMenuSticked: boolean;
  isMenuOpened: boolean;
  isCloseTransitionRunning: boolean;
  isIntroVisible?: boolean;
  isMenuItemTransitionRunningRunning?: boolean;
}

export class Header extends React.Component<PropsModel, StateModel> {
  constructor(props: any) {
    super(props);

    this.state = {
      isMenuSticked: false,
      isMenuOpened: false,
      isCloseTransitionRunning: false,
      isIntroVisible: props.isHome,
      isMenuItemTransitionRunningRunning: false,
    };

    this.showMobileMenu = this.showMobileMenu.bind(this);
  }

  switchMenuStates(): void {
    if (window.scrollY > 40) {
      this.setState(() => ({ isMenuSticked: true }));
    } else {
      this.setState(() => ({ isMenuSticked: false }));
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.switchMenuStates();

    window.addEventListener("IntroInView", ((event: CustomEvent) => {
      this.setState(
        () => ({
          isIntroVisible: event.detail.inView,
          isMenuItemTransitionRunningRunning: true,
        }),
        () => {
          setTimeout(() => {
            this.setState(() => ({
              isMenuItemTransitionRunningRunning: false,
            }));
          }, 200);
        }
      );
    }) as EventListener);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    this.switchMenuStates();
  };

  showMobileMenu(toggled: boolean): void {
    this.setState(() => ({ isMenuOpened: toggled }));

    if (toggled) {
      document.body.style.position = "relative";
      document.body.style.overflow = "hidden";
    } else {
      this.setState(
        () => ({ isCloseTransitionRunning: true }),
        () => {
          setTimeout(() => {
            document.body.style.position = "static";
            document.body.style.overflow = "unset";
            this.setState(() => ({ isCloseTransitionRunning: false }));
          }, 500);
        }
      );
    }
  }

  render() {
    return (
      <Wrapper isSticked={this.state.isMenuSticked}>
        <StyledCenteredRow>
          <ConditionalWrapper
            condition={this.props.isHome}
            wrapper={(children) => <h1>{children}</h1>}
          >
            <LogoLink
              to="/"
              state={{
                isMenuOpened: this.state.isMenuOpened,
                isCloseTransitionRunning: this.state.isCloseTransitionRunning,
              }}
              onClick={scrollToTop}
              aria-label="Accueil Hello Joko"
            >
              <Logo src={LogoSrc} alt="Joko" />
            </LogoLink>
          </ConditionalWrapper>
          <HeaderMenuOpenedContext.Provider
            value={
              this.state.isCloseTransitionRunning || this.state.isMenuOpened
            }
          >
            <StyledMenu
              isMenuItemTransitionRunning={
                this.state.isMenuItemTransitionRunningRunning
              }
              isOnIntro={this.state.isIntroVisible}
              isOpened={this.state.isMenuOpened}
              isCloseTransitionRunning={this.state.isCloseTransitionRunning}
            />
          </HeaderMenuOpenedContext.Provider>
          <StyledToggler
            onToggle={this.showMobileMenu}
            isMenuOpened={this.state.isMenuOpened}
            isCloseTransitionRunning={this.state.isCloseTransitionRunning}
          />
        </StyledCenteredRow>
      </Wrapper>
    );
  }
}
