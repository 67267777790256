import React from "react";
import styled from "styled-components";
import { useIntl } from "gatsby-plugin-intl";

import { theme } from "../theme/theme";
import FacebookLogoSvg from "../images/wrong-facebook-logo.inline.svg";
import InstagramLogoSvg from "../images/instagram-logo.inline.svg";
import LinkedInLogoSvg from "../images/linkedin-logo.inline.svg";
import TikTokLogoSvg from "../images/tiktok-logo.inline.svg";
import TwitterLogoSvg from "../images/twitter-logo.inline.svg";

const MenuList = styled.ul`
  margin: -0.8125em;
`;

const MenuListItem = styled.li`
  display: inline-block;
  vertical-align: middle;
  margin: 0.8125em;

  a {
    transition: all 0.2s ease-in-out;

    &:hover,
    &:active,
    &:focus {
      fill: ${theme.palette.primary.darker};
    }
  }
`;

export const SocialMediaMenu = () => {
  return (
    <MenuList>
      <MenuListItem>
        <a href={process.env.INSTAGRAM_ACCOUNT_LINK} target="_blank">
          <InstagramLogoSvg />
        </a>
      </MenuListItem>
      <MenuListItem>
        <a href={process.env.FACEBOOK_ACCOUNT_LINK} target="_blank">
          <FacebookLogoSvg />
        </a>
      </MenuListItem>
      <MenuListItem>
        <a href={process.env.TWITTER_ACCOUNT_LINK} target="_blank">
          <TwitterLogoSvg />
        </a>
      </MenuListItem>
      <MenuListItem>
        <a href={process.env.LINKEDIN_ACCOUNT_LINK} target="_blank">
          <LinkedInLogoSvg />
        </a>
      </MenuListItem>
      <MenuListItem>
        <a href={process.env.TIKTOK_ACCOUNT_LINK} target="_blank">
          <TikTokLogoSvg />
        </a>
      </MenuListItem>
    </MenuList>
  );
};
