import React from "react";
import styled from "styled-components";
import { useIntl } from "gatsby-plugin-intl";

import { Locale } from "../../utils/region";
import { theme } from "../../theme/theme";
import {
  headerLowerThanMDMediaQuery,
  HeaderMenuOpenedContext,
} from "../header";
import { ButtonPropsModel, LinkAsButton } from "./button";

export const BrowserExtensionButton = () => {
  const intl = useIntl();
  const locale = intl.locale as Locale;
  const region = locale === "en" ? "US" : "FR";
  const downloadLink = new URL(process.env.BROWSER_EXTENSION_LINK as string);
  downloadLink.searchParams.set("region", region);
  return (
    <HeaderMenuOpenedContext.Consumer>
      {(value) => (
        <InstallBrowserExtensionButton
          bigger
          openwork
          isInsideOpenedMobileMenu={value}
          href={downloadLink.toString()}
          target="_blank"
          as="a"
        >
          {intl.formatMessage({ id: "home.downloadButtons.browserExtension" })}
        </InstallBrowserExtensionButton>
      )}
    </HeaderMenuOpenedContext.Consumer>
  );
};

interface InstallBrowserExtensionButtonPropsModel extends ButtonPropsModel {
  isInsideOpenedMobileMenu?: boolean;
}

const InstallBrowserExtensionButton = styled(
  LinkAsButton
)<InstallBrowserExtensionButtonPropsModel>`
  ${(props: InstallBrowserExtensionButtonPropsModel) => {
    if (props.isInsideOpenedMobileMenu) {
      return `
        ${headerLowerThanMDMediaQuery} {
          &:hover,
          &:active,
          &:focus {
            border-color: ${theme.palette.primary.inverted};
          }

          outline-color: ${theme.palette.secondary.inverted};
        }
      `;
    }
  }}
`;
