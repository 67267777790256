import AmbitRegularWoff2 from "./Ambit-Regular.woff2";
import AmbitSemiBoldWoff2 from "./Ambit-SemiBold.woff2";
import AmbitBlackWoff2 from "./Ambit-Black.woff2";
import AmbitRegularWoff from "./Ambit-Regular.woff";
import AmbitSemiBoldWoff from "./Ambit-SemiBold.woff";
import AmbitBlackWoff from "./Ambit-Black.woff";

import IconsWoff from "./icons.woff";
import IconsSVG from "./icons.svg";

//TODO: check licence

export default `
    @font-face {
      font-family: "Ambit";
      src: url(${AmbitRegularWoff2}) format('woff2'), url(${AmbitRegularWoff}) format('woff');
      font-weight: 400;
      font-style: normal;
      font-display: block;
    }

    @font-face {
      font-family: "Ambit";
      src: url(${AmbitSemiBoldWoff2}) format('woff2'), url(${AmbitSemiBoldWoff}) format('woff');
      font-weight: 600;
      font-style: normal;
      font-display: block;
    }

    @font-face {
      font-family: "Ambit";
      src: url(${AmbitBlackWoff2}) format('woff2'), url(${AmbitBlackWoff}) format('woff');
      font-weight: 800;
      font-style: normal;
      font-display: block;
    }

    @font-face {
      font-family: "Icons";
      src: url(${IconsWoff}) format('woff'), url(${IconsSVG}) format('svg');
      font-weight: normal;
      font-style: normal;
    }
`;
