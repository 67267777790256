import { Link } from "gatsby";
import styled, { css } from "styled-components";
import { theme } from "../../theme/theme";

export interface ButtonPropsModel {
  bigger?: boolean;
  white?: boolean;
  greyBorder?: boolean;
  openwork?: boolean;
  shadow?: boolean;
  rounded?: boolean;
  unselected?: boolean;
}

function setSizes(props: ButtonPropsModel) {
  const heightEmValue: number = props.bigger ? 3.5 : 3;
  const paddingSideEmValue: number = props.bigger ? 2.5 : 1.25;
  const borderWidthPxValue: number = 2;

  let styles: css = `
    box-sizing: border-box;
    border-radius: ${heightEmValue / 2}em;
    line-height: 1em;
  `;

  if (props.rounded) {
    styles += `
      height: calc(${heightEmValue}em - ${borderWidthPxValue}px);
      width: calc(${heightEmValue}em - ${borderWidthPxValue}px);
    `;
  } else {
    styles += `
      width: auto;
      padding: ${(heightEmValue - 1) / 2}em ${paddingSideEmValue}em;
    `;
  }

  if (props.openwork) {
    if (!props.rounded) {
      styles += `
        padding: calc(${
          (heightEmValue - 1) / 2
        }em - ${borderWidthPxValue}px) calc(${paddingSideEmValue}em - ${borderWidthPxValue}px);
      `;
    }

    styles += `
      border: ${borderWidthPxValue}px solid;
    `;
  } else {
    styles += `border: none;`;
  }

  return styles;
}

function setColors(props: ButtonPropsModel): css {
  let styles: css = `
    transition: opacity .2s ease-in-out, background-color .2s ease-in-out, color .2s ease-in-out, border-color .2s ease-in-out;

    svg {
      transition: all .2s ease-in-out;
    }
  `;

  if ((props.openwork && props.white) || (!props.openwork && !props.white)) {
    styles += `
      color: ${theme.palette.common.white};

      svg {
        fill: ${theme.palette.common.white};
        stroke: ${theme.palette.common.white};
      }
    `;
  } else {
    styles += `
      color: ${theme.palette.common.black};
      
      svg {
        fill: ${theme.palette.common.black};
        stroke: ${theme.palette.common.black};
      }
    `;
  }

  if (props.openwork) {
    styles += `
      background: transparent;
      border-color: ${
        props.white
          ? theme.palette.common.white
          : props.greyBorder
          ? theme.palette.common.grey
          : theme.palette.common.black
      };

      &:hover,
      &:active,
      &:focus {
        opacity: 1;
        border-color: ${
          props.white
            ? theme.palette.primary.basic
            : theme.palette.primary.darker
        };

        svg {
          stroke: ${
            props.white
              ? theme.palette.primary.basic
              : theme.palette.primary.darker
          };
          fill: ${
            props.white
              ? theme.palette.primary.basic
              : theme.palette.primary.darker
          };
        }
      }
    `;
  } else {
    styles += `
      background-color: ${
        props.white ? theme.palette.common.white : theme.palette.common.black
      };

      &:hover,
      &:active,
      &:focus {
        opacity: 1;
        background-color: ${
          props.white
            ? theme.palette.primary.basic
            : theme.palette.primary.darker
        };
      }
    `;
  }

  if (props.unselected) {
    styles += `
      opacity: .5;
    `;
  }

  styles += `
    &:disabled {
      opacity: .5;
      cursor: not-allowed;
    }
  `;

  return styles;
}

const ButtonStyle: css = css`
  ${(props: ButtonPropsModel) => setSizes(props)}
  ${(props: ButtonPropsModel) => setColors(props)}
  ${(props: ButtonPropsModel) => {
    if (props.shadow) {
      return `
        filter: drop-shadow(0 0 0.15em rgba(0, 0, 0, .1));
      `;
    }
  }}
`;

export const Button = styled.button<ButtonPropsModel>`
  ${ButtonStyle}
  margin: 0;
  overflow: visible;
  font: inherit;
  text-align: inherit;
  cursor: pointer;

  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;

  &:-moz-focus-inner {
    border: 0;
    padding: 0;
  }
`;

export const LinkAsButton = styled(Link)<ButtonPropsModel>`
  ${ButtonStyle}
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
`;
