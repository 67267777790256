export enum OS {
  Desktop = "desktop",
  IOs = "ios",
  Android = "android",
}

export function detectOs(): OS {
  if (typeof window === "undefined") {
    return OS.Desktop;
  }
  const userAgent = navigator.userAgent || navigator.vendor;
  if (/android/i.test(userAgent)) {
    return OS.Android;
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return OS.IOs;
  }
  return OS.Desktop;
};
