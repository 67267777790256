import { createGlobalStyle } from "styled-components";
import { css } from "styled-components";
import { FontModel } from "../models/theme.model";
import { theme } from "../theme/theme";

export const gtMDMediaQuery: string = "@media screen and (min-width: 48em)"; //768px
export const ltMDMediaQuery: string =
  "@media screen and (max-width: 47.9375em)"; //767px

const textStyles: css = (font: FontModel) => {
  return `
    ${font.size ? `font-size: ${font.size};` : ""}
    ${font.weight ? `font-weight: ${font.weight};` : ""}
    ${font.style ? `font-style: ${font.style};` : ""}
    ${font.lineHeight ? `line-height: ${font.lineHeight};` : ""}
    ${font.letterSpacing ? `letter-spacing: ${font.letterSpacing};` : ""}
  `;
};

export default createGlobalStyle`
  body {
    font-family: Ambit, sans-serif;
  }

  @media screen and (min-width: 59.375em) {
    body {
      position: static;
      overflow: unset!important; // in case the mobile menu is opened before the window is enlarged
    }
  }

  &::selection {
    background: ${theme.palette.secondary.basic};
  }

  a, button {
    outline-color: ${theme.palette.secondary.basic};
    word-break: break-word;
  }

  em {
    color: ${theme.palette.primary.basic}
  }

  .text {
    ${textStyles(theme.fonts.basic)}

    &:not(:first-child) {
      margin-top: 1em;
    }

    &--bold,
    b, strong {
      font-weight: ${theme.fonts.emphasized.weight};
    }
  }

  .ul-list {
    padding-left: 1em;
    margin-top: 1em;
    list-style: disc;

    & & {
      list-style: circle;
    }

    & & & {
      list-style: square;
    }
  }

  .emphasized-text {
    ${textStyles(theme.fonts.basic)}
    font-weight: ${theme.fonts.emphasized.weight};
    
    ${gtMDMediaQuery} {
      font-size: ${theme.fonts.emphasized.size};
      line-height: ${theme.fonts.emphasized.lineHeight};
    }

    &:not(:first-child) {
      margin-top: 1em;
    }
  }

  .text,
  .emphasized-text {
    i {
      font-style: italic;
    }

    .link {
      box-shadow: inset 0 0 0 ${theme.palette.common.black}, inset 0 -1px 0 ${theme.palette.common.black};
      color: ${theme.palette.common.black};
      font-weight: 600;
      text-decoration: none;
      transition: color .2s ease-in-out,box-shadow .2s ease-in-out;
  
      &:hover,
      &:active,
      &:focus {
        color: ${theme.palette.primary.darker};
        box-shadow: inset 0 0 0 ${
          theme.palette.primary.darker
        }, inset 0 -1px 0 ${theme.palette.primary.darker};
      }
    }
  }

  .very-small-heading {
    ${textStyles(theme.fonts.headingXS)}
    text-transform: uppercase;
  }

  .small-heading {
    ${textStyles(theme.fonts.headingSM)}
    text-transform: uppercase;

    &:not(:first-child) {
      margin-top: 2em;
    }
  }

  .heading {
    ${textStyles(theme.fonts.headingSM)}
    text-transform: uppercase;

    ${gtMDMediaQuery} {
      ${textStyles(theme.fonts.headingMD)}
    }

    &:not(:first-child) {
      margin-top: 1em;
    }
  }

  .big-heading {
    ${textStyles(theme.fonts.headingMD)}
    text-transform: uppercase;

    ${gtMDMediaQuery} {
      ${textStyles(theme.fonts.headingXL)}
    }
  }

  .icon {
    &::before {
      font-family: "Icons";
      content: "";
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      speak: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    &-apple {
      &::before {
        content: "a";
      }
    }
  }
`;
