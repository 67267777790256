import React from "react";
import styled from "styled-components";

import { detectOs, OS } from "../utils/detect-os";

import UserSvg from "../images/user.inline.svg";

import { theme } from "../theme/theme";

import { ButtonPropsModel, LinkAsButton } from "./common/button";
import { GooglePlayButton } from "./common/googleplay-button";
import { AppleStoreButton } from "./common/applestore-button";
import { BrowserExtensionButton } from "./common/browser-extension-button";

import { headerLowerThanMDMediaQuery, HeaderMenuOpenedContext } from "./header";

const Nav = styled.nav``;

const StyledUserSvg = styled(UserSvg)`
  stroke: ${theme.palette.common.black};
`;

export const MenuList = styled.ul`
  margin: -0.8125em;
`;

export const MenuListItem = styled.li`
  display: inline-block;
  vertical-align: middle;
  margin: 0.8125em;
`;

interface LoginButtonPropsModel extends ButtonPropsModel {
  isInsideOpenedMobileMenu?: boolean;
}

const LoginButton = styled(LinkAsButton)<LoginButtonPropsModel>`
  ${(props: LoginButtonPropsModel) => {
    if (props.isInsideOpenedMobileMenu) {
      return `
        ${headerLowerThanMDMediaQuery} {
          &:hover,
          &:active,
          &:focus {
            border-color: ${theme.palette.primary.inverted};
            
            svg {
              stroke: ${theme.palette.primary.inverted};
              fill: ${theme.palette.primary.inverted};
            }
          }

          outline-color: ${theme.palette.secondary.inverted};
        }
      `;
    }
  }}
`;

export class Menu extends React.Component<any, any> {
  os: OS;

  constructor(props: {}) {
    super(props);

    this.os = detectOs();
  }

  render() {
    return (
      <Nav className={this.props.className}>
        <MenuList>
          {this.os === OS.Desktop || this.os === OS.Android ? (
            <MenuListItem>
              <GooglePlayButton />
            </MenuListItem>
          ) : null}

          {this.os === OS.Desktop || this.os === OS.IOs ? (
            <MenuListItem>
              <AppleStoreButton />
            </MenuListItem>
          ) : null}

          {this.os !== OS.Android && this.os !== OS.IOs ? (
            <MenuListItem>
              <BrowserExtensionButton />
            </MenuListItem>
          ) : null}

          <MenuListItem>
            <HeaderMenuOpenedContext.Consumer>
              {(value) => (
                <LoginButton
                  bigger
                  openwork
                  rounded
                  isInsideOpenedMobileMenu={value}
                  href={process.env.WEBAPP_LINK}
                  target="_blank"
                  aria-label="Connexion"
                  as="a"
                >
                  <StyledUserSvg />
                </LoginButton>
              )}
            </HeaderMenuOpenedContext.Consumer>
          </MenuListItem>
        </MenuList>
      </Nav>
    );
  }
}
