import { ThemeModel } from "../models/theme.model";

export const theme: ThemeModel = {
  fonts: {
    basic: {
      lineHeight: 26 / 16,
      weight: 400,
      style: "normal",
    },
    emphasized: {
      size: "1.25em",
      lineHeight: 30 / 20,
      weight: 600,
      style: "normal",
    },
    headingXS: {
      size: "0.75em", // 12px
      lineHeight: 1,
      weight: 800,
      style: "normal",
    },
    headingSM: {
      size: "1.4375em", // 23px
      lineHeight: 29 / 23,
      weight: 800,
      style: "normal",
    },
    headingMD: {
      size: "2.5em", // 40px
      lineHeight: 45 / 40,
      letterSpacing: "-0.03em",
      weight: 800,
      style: "normal",
    },
    headingXL: {
      size: "4.2em", // 67px
      //lineHeight: 58 / 72,
      letterSpacing: "-0.03em",
      weight: 800,
      style: "normal",
    },
  },
  palette: {
    common: {
      white: "rgb(255, 255, 255)",
      black: "rgb(0, 0, 0)",
      grey: "rgb(235, 235, 235)",
    },
    primary: {
      basic: "rgb(81, 240, 114)",
      darker: "rgb(39, 213, 77)",
      inverted: "rgb(137, 0, 131)",
    },
    secondary: {
      basic: "rgb(215, 166, 255)",
      darker: "rgb(141, 63, 239)",
      inverted: "rgb(54, 85, 11)",
    },
  },
};
