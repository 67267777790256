import styled, { css } from "styled-components";

let row: css = `
  box-sizing: border-box;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1.25em; // 20
  padding-left: 1.25em;
`;

export const CenteredRow = styled.div`
  ${row}
  max-width: 78.75em; // 1220 + padding
`;

export const HalfCenteredRow = styled.div`
  ${row}
  max-width: 35.625em; // 530 + padding
  text-align: center;
`;

interface BreakpointModel {
  value: number;
  portion: number;
  gutter: number;
}

interface ColumnPropsModel {
  breakpoints: BreakpointModel[];
}

function buildColumns(portion: number, gutter: number): css {
  if (portion < 1) {
    const gutterWidth: number = gutter / 16;

    if (portion == 0.5) {
      return `
      width: 50%;

      &:nth-child(odd) {
        padding-right: ${gutterWidth / 2}em;
      }

      &:nth-child(even) {
        padding-left: ${gutterWidth / 2}em;
      }
    `;
    } else {
      const totalOfCols: number = Math.round(1 / portion);

      return `
        width: calc(${100 / totalOfCols}% + ${gutterWidth / totalOfCols}em);
        padding-right: ${gutterWidth / 2}em;
        padding-left: ${gutterWidth / 2}em;

        &:nth-child(${totalOfCols}n),
        &:nth-child(${totalOfCols}n + 1) {
          width: calc(${100 / totalOfCols}% - ${
        ((gutterWidth / 2) * (totalOfCols - 2)) / totalOfCols
      }em);
        }

        &:nth-child(${totalOfCols}n) {
          padding-right: 0;
        }

        &:nth-child(${totalOfCols}n + 1)  {
          padding-left: 0;
        }
      `;
    }
  }
}

export const Column = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

export const CenteredRowWithColumns = styled(CenteredRow)<ColumnPropsModel>`
  display: flex;
  flex-wrap: wrap;

  ${Column} {
    ${(props: ColumnPropsModel) => {
      return props.breakpoints.map((breakpoint: BreakpointModel, i: number) => {
        return `@media screen and (min-width: ${breakpoint.value / 16}em) { 
          ${buildColumns(breakpoint.portion, breakpoint.gutter)} 
        }`;
      });
    }}
  }
`;
