import styled from "styled-components";
import { ButtonPropsModel, LinkAsButton } from "./button";
import { theme } from "../../theme/theme";
import { headerLowerThanMDMediaQuery } from "../header";

interface GoToStoreButtonPropsModel extends ButtonPropsModel {
  isInsideOpenedMobileMenu?: boolean;
}

export const GoToStoreButton = styled(LinkAsButton)<GoToStoreButtonPropsModel>`
  display: flex;
  align-items: center;
  width: fit-content;

  ${(props: GoToStoreButtonPropsModel) => {
    if (props.isInsideOpenedMobileMenu) {
      return `
        ${headerLowerThanMDMediaQuery} {
          &:hover,
          &:active,
          &:focus {
            background-color: ${theme.palette.primary.inverted};
          }
        }

        outline-color: ${theme.palette.secondary.inverted};
      `;
    }
  }}
`;
