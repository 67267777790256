import React from "react";
import styled from "styled-components";
import { useIntl } from "gatsby-plugin-intl";

import { HeaderMenuOpenedContext } from "../header";
import { GoToStoreButton } from "./go-to-store-button";

export const AppleStoreButton = () => {
  const intl = useIntl();
  return (
    <HeaderMenuOpenedContext.Consumer>
      {(value) => (
        <StyledGoToStoreButton
          bigger
          isInsideOpenedMobileMenu={value}
          href={
            intl.locale === "en"
              ? process.env.MAGICSTORE_LINK_US
              : process.env.MAGICSTORE_LINK_FR
          }
          target="_blank"
          as="a"
        >
          <Logo className="icon icon-apple"></Logo>
          <AppleLabel>
            {intl.formatMessage({ id: "home.downloadButtons.appStore" })}
          </AppleLabel>
        </StyledGoToStoreButton>
      )}
    </HeaderMenuOpenedContext.Consumer>
  );
};

const StyledGoToStoreButton = styled(GoToStoreButton)`
  padding-top: 0;
  padding-bottom: 0;
  min-height: 3.5em;
`;

const Logo = styled.span`
  font-size: 1.4em;
`;

const AppleLabel = styled.span`
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 0.875em;
  margin-left: 0.5em;
`;
