import React from "react";
import styled from "styled-components";
import { useIntl } from "gatsby-plugin-intl";

import { HeaderMenuOpenedContext } from "../header";

import GooglePlaySvg from "../../images/google-play.inline.svg";
import { GoToStoreButton } from "./go-to-store-button";

const StyledGoToStoreButton = styled(GoToStoreButton)`
  padding-top: 0;
  padding-bottom: 0;
  min-height: 3.5em;
`;

export const GooglePlayButton = () => {
  const intl = useIntl();
  return (
    <HeaderMenuOpenedContext.Consumer>
      {(value) => (
        <StyledGoToStoreButton
          bigger
          isInsideOpenedMobileMenu={value}
          href={
            intl.locale === "en"
              ? process.env.MAGICSTORE_LINK_US
              : process.env.MAGICSTORE_LINK_FR
          }
          target="_blank"
          as="a"
        >
          <GooglePlaySvg height="56" width="103" />
        </StyledGoToStoreButton>
      )}
    </HeaderMenuOpenedContext.Consumer>
  );
};
